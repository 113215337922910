import SectionHeader from '../components/sections/header';
import SectionBreadcrumbs from '../components/sections/breadcrumbs';
import SectionProductSimple from '../components/sections/product-simple';
import SectionProductSlider from '../components/sections/product-slider';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql } from 'gatsby';

import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IProduct, IProductOption } from '../models/product.model';
import { ISetting } from '../models/setting.model';

import { ProductContextProvider } from '../contexts/product-content';
import { getNodes } from '../utils/get-nodes';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import ThemeStyle from '../components/atoms/theme-style';

interface IPostProps {
    readonly data: {
        page: IPage;
        product: IProduct;
        allThemeSetting: IQueryAllResult<ISetting>;
    };
}

const Product: React.FC<IPostProps> = ({ data }) => {
    const { page, product, allThemeSetting } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);

    const sections = page.sections.map((section) => {
        return section.type === 'product-simple' || section.type === 'product-sticky'
            ? { ...section, settings: { ...section.settings, isMain: true } }
            : section;
    });
    const { mainSectionIndex } = getPageConfigFromSections(sections);

    const sectionsWithData = sections
        .map((section) => {
            if (section.type === 'product-simple' || section.type === 'product-sticky') {
                return {
                    ...section,
                    extendedItems: {
                        product,
                    },
                };
            }

            if (section.type === 'product-slider') {
                if (product.relatedProducts && product.relatedProducts.length === 0) return null;

                return {
                    ...section,
                    items: {
                        products: product.relatedProducts,
                    },
                };
            }
            return section;
        })
        .filter(nonNullable);

    const preselectedOptions = getOptions(
        product.variants && product.variants?.length > 0
            ? product.variants[0].variantId
            : undefined,
        product.options
    );

    return (
        <MainLayout>
            <ThemeStyle style={customStyle} />
            <ProductContextProvider
                options={preselectedOptions}
                variant={product.variants ? product.variants[0] : undefined}
            >
                {sectionsWithData.map((section, index) => {
                    return (
                        <SectionFactory
                            key={`section-${section.type}-${section.sectionId}`}
                            SectionComponent={sectionComponents[section.type]}
                            section={section}
                            TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                        />
                    );
                })}
            </ProductContextProvider>
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $productId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }) {
            sections {
                ...sectionFields
            }
        }
        product(productId: { eq: $productId }, locale: { eq: $locale }) {
            ...productFields
            relatedProducts {
                ...productFields
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

function nonNullable<T>(value: T): value is NonNullable<T> {
    return Boolean(value);
}

const getOptions = (variant: number | undefined, options: IProductOption[] | undefined) => {
    if (variant && options) {
        return options?.map((option) => {
            let found = undefined;
            option.values.forEach((value) => {
                if (value.variants.includes(variant)) found = value;
            });
            return found;
        });
    }
    return options?.map(() => undefined);
};

export default Product;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'breadcrumbs': SectionBreadcrumbs,
    'product-simple': SectionProductSimple,
    'product-slider': SectionProductSlider,
    'footer': SectionFooter,
};